<template>
  <div id="app">
    <header>
      <div class="header-content">
        <h1>{{ t.schoolName }}</h1>
        <nav>
          <ul>
            <li v-for="(item, key) in t.nav" :key="key">
              <a :href="'#' + key">{{ item }}</a>
            </li>
          </ul>
        </nav>
        <button @click="toggleLanguage">
          {{ currentLang === "en" ? "中文" : "English" }}
        </button>
      </div>
    </header>

    <main>
      <section id="home" class="hero">
        <div class="hero-content">
          <h2>
            {{ t.hero.welcome }} <br />
            <span>{{ t.schoolName }}</span>
          </h2>
          <p>{{ t.hero.tagline }}</p>
          <a href="#programs" class="cta-button">{{ t.cta }}</a>
        </div>
      </section>

      <section id="about" class="container">
        <h2>{{ t.about.title }}</h2>
        <div class="about-content">
          <p>{{ t.about.description }}</p>
          <ul class="about-features">
            <li v-for="(feature, index) in t.about.features" :key="index">
              {{ feature }}
            </li>
          </ul>
        </div>
      </section>

      <section id="programs" class="container">
        <h2>{{ t.programs.title }}</h2>
        <div class="program-grid">
          <div class="program-card">
            <h3>{{ t.programs.introPython.title }}</h3>
            <p>{{ t.programs.introPython.description }}</p>
            <ul>
              <li
                v-for="(topic, index) in t.programs.introPython.topics"
                :key="index"
              >
                {{ topic }}
              </li>
            </ul>
            <span class="age-group">{{ t.programs.introPython.ageGroup }}</span>
          </div>
          <div class="program-card">
            <h3>{{ t.programs.advJava.title }}</h3>
            <p>{{ t.programs.advJava.description }}</p>
            <ul>
              <li
                v-for="(topic, index) in t.programs.advJava.topics"
                :key="index"
              >
                {{ topic }}
              </li>
            </ul>
            <span class="age-group">{{ t.programs.advJava.ageGroup }}</span>
          </div>
          <div class="program-card">
            <h3>{{ t.programs.apcsa.title }}</h3>
            <p>{{ t.programs.apcsa.description }}</p>
            <ul>
              <li
                v-for="(topic, index) in t.programs.apcsa.topics"
                :key="index"
              >
                {{ topic }}
              </li>
            </ul>
            <span class="age-group">{{ t.programs.apcsa.ageGroup }}</span>
          </div>
          <div class="program-card">
            <h3>{{ t.programs.cccJunior.title }}</h3>
            <p>{{ t.programs.cccJunior.description }}</p>
            <ul>
              <li
                v-for="(topic, index) in t.programs.cccJunior.topics"
                :key="index"
              >
                {{ topic }}
              </li>
            </ul>
            <span class="age-group">{{ t.programs.cccJunior.ageGroup }}</span>
          </div>
          <div class="program-card">
            <h3>{{ t.programs.cccSenior.title }}</h3>
            <p>{{ t.programs.cccSenior.description }}</p>
            <ul>
              <li
                v-for="(topic, index) in t.programs.cccSenior.topics"
                :key="index"
              >
                {{ topic }}
              </li>
            </ul>
            <span class="age-group">{{ t.programs.cccSenior.ageGroup }}</span>
          </div>
          <div class="program-card">
            <h3>{{ t.programs.cpsc310.title }}</h3>
            <p>{{ t.programs.cpsc310.description }}</p>
            <ul>
              <li
                v-for="(topic, index) in t.programs.cpsc310.topics"
                :key="index"
              >
                {{ topic }}
              </li>
            </ul>
            <span class="age-group">{{ t.programs.cpsc310.ageGroup }}</span>
          </div>
          <div class="program-card">
            <h3>{{ t.programs.newGradInterview.title }}</h3>
            <p>{{ t.programs.newGradInterview.description }}</p>
            <ul>
              <li
                v-for="(topic, index) in t.programs.newGradInterview.topics"
                :key="index"
              >
                {{ topic }}
              </li>
            </ul>
            <span class="age-group">{{
              t.programs.newGradInterview.ageGroup
            }}</span>
          </div>
        </div>
      </section>

      <section id="contact" class="container">
        <h2>{{ t.contact.title }}</h2>
        <div class="contact-content">
          <p>{{ t.contact.addressLabel }} {{ t.contact.addressValue }}</p>
          <p>
            {{ t.contact.canadianAddressLabel }}
            {{ t.contact.canadianAddressValue }}
          </p>
          <p>{{ t.contact.phone }} US&CA: 778-859-5468</p>
          <p>{{ t.contact.email }} admin@yl-technology-education.com</p>
        </div>
      </section>
    </main>

    <footer>
      <p>&copy; 2023 {{ t.schoolName }} All rights reserved.</p>
    </footer>
  </div>
</template>

<script>
import translations from "./translations.json";
export default {
  name: "App",
  data() {
    return {
      currentLang: "en",
      translations,
    };
  },
  computed: {
    t() {
      return this.translations[this.currentLang];
    },
  },
  methods: {
    toggleLanguage() {
      this.currentLang = this.currentLang === "en" ? "zh" : "en";
    },
  },
};
</script>

<style>
#app {
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  color: #333;
}

header {
  background-color: rgba(44, 62, 80, 0.95);
  color: white;
  padding: 1rem 0;
  position: fixed;
  width: 100%;
  z-index: 1000;
  transition: all 0.3s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

header.scrolled {
  background-color: rgba(44, 62, 80, 1);
  padding: 0.5rem 0;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

header h1 {
  margin: 0;
  font-size: 1.8rem;
  font-weight: 700;
  letter-spacing: 1px;
}

header h1 a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

header h1 a:hover {
  color: #3498db;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

nav ul li {
  margin-left: 2rem;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-weight: 600;
  font-size: 1rem;
  transition: all 0.3s ease;
  padding: 0.5rem 0;
  position: relative;
}

nav ul li a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #3498db;
  transition: width 0.3s ease;
}

nav ul li a:hover {
  color: #3498db;
}

nav ul li a:hover::after {
  width: 100%;
}

/* Responsive design for header */
@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    align-items: flex-start;
  }

  nav {
    margin-top: 1rem;
    width: 100%;
  }

  nav ul {
    flex-direction: column;
  }

  nav ul li {
    margin-left: 0;
    margin-bottom: 0.5rem;
  }
}

main {
  padding: 2rem;
}

section {
  margin-bottom: 2rem;
}

footer {
  background-color: #4a4a4a;
  color: white;
  text-align: center;
  padding: 1rem;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.program-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.program-card {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.program-card:hover {
  transform: translateY(-5px);
}

.program-card h3 {
  color: #2c3e50;
  margin-top: 0;
}

.program-card p {
  font-size: 0.9rem;
  color: #555;
}

.program-card ul {
  padding-left: 1.2rem;
  margin-bottom: 1rem;
}

.program-card ul li {
  font-size: 0.85rem;
  margin-bottom: 0.3rem;
}

.age-group {
  display: inline-block;
  background-color: #3498db;
  color: white;
  padding: 0.3rem 0.6rem;
  border-radius: 20px;
  font-size: 0.8rem;
  font-weight: bold;
}

.hero {
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("https://images.unsplash.com/photo-1517694712202-14dd9538aa97?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  position: relative;
  overflow: hidden;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    rgba(52, 152, 219, 0.3),
    rgba(41, 128, 185, 0.3)
  );
  z-index: 1;
}

.hero-content {
  max-width: 800px;
  padding: 3rem;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  backdrop-filter: blur(10px);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 2;
  animation: fadeIn 1s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hero h2 {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.hero h2 span {
  color: #3498db;
  font-weight: bold;
}

.hero-content p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  line-height: 1.6;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.cta-button {
  display: inline-block;
  background-color: #3498db;
  color: white;
  padding: 1rem 2rem;
  border-radius: 50px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.1rem;
  position: relative;
  overflow: hidden;
}

.cta-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.3),
    transparent
  );
  transition: all 0.6s;
}

.cta-button:hover::before {
  left: 100%;
}

.cta-button:hover {
  background-color: #2980b9;
  transform: translateY(-3px);
  box-shadow: 0 4px 15px rgba(52, 152, 219, 0.4);
}

/* Responsive design for hero section */
@media (max-width: 768px) {
  .hero h2 {
    font-size: 2.5rem;
  }

  .hero-content p {
    font-size: 1.2rem;
  }

  .hero-content {
    padding: 2rem;
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

/* About Us Section Styles */
#about {
  padding: 4rem 0;
  background-color: #f8f9fa;
}

#about h2 {
  font-size: 2.5rem;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 2rem;
}

.about-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 800px;
  margin: 0 auto;
}

.about-content p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #333;
  margin-bottom: 1rem;
}

.about-features {
  list-style-type: none;
  padding: 0;
}

.about-features li {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #333;
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  position: relative;
}

.about-features li::before {
  content: "•";
  color: #3498db;
  font-size: 1.5rem;
  position: absolute;
  left: 0;
  top: -0.2rem;
}

@media (min-width: 768px) {
  .about-content {
    flex-direction: row;
  }

  .about-content p {
    flex: 1;
    padding-right: 2rem;
  }

  .about-features {
    flex: 1;
  }
}

/* Enhanced Contact Us Section Styles */
#contact {
  background-color: #f8f9fa;
  padding: 4rem 0;
}

#contact h2 {
  font-size: 2.5rem;
  color: #2c3e50;
  text-align: center;
  margin-bottom: 2rem;
}

.contact-content {
  max-width: 600px;
  margin: 0 auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.contact-content p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1rem;
  display: flex;
  align-items: flex-start;
}

.contact-content p:last-child {
  margin-bottom: 0;
}

.contact-content p::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 1rem;
  background-size: contain;
  background-repeat: no-repeat;
  flex-shrink: 0;
}

.contact-content p:nth-child(1)::before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%233498db"><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/></svg>');
}

.contact-content p:nth-child(2)::before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%233498db"><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/></svg>');
}

.contact-content p:nth-child(3)::before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%233498db"><path d="M20 15.5c-1.25 0-2.45-.2-3.57-.57-.35-.11-.74-.03-.97.24l-1.97 1.97c-2.81-1.44-5.15-3.75-6.59-6.59l1.97-1.97c.23-.23.24-.62.04-.98A10.48 10.48 0 0012 2C6.48 2 2 6.48 2 12s4.48 10 10 10c1.85 0 3.54-.63 4.88-1.68l1.95 1.95c.2.2.45.29.7.29.28 0 .55-.11.75-.31a10.97 10.97 0 004-4c0-1.25-.2-2.45-.57-3.57z"/></svg>');
}

.contact-content p:nth-child(4)::before {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%233498db"><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/></svg>');
}

button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #2980b9;
}
</style>
